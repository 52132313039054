import type { HiveContent } from "~/utils/hive";
import { getAvatar } from "~/utils/hive";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link, useLocation } from "@remix-run/react";
import { useAppStore } from "~/store";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cache } from "~/utils/cache";
import { cn } from "~/utils/cn";

interface SmallAvatarProps {
  author: HiveContent["author"];
  size?: number;
  className?: string;
  disableThreadcast?: boolean;
}

export const SmallAvatar = ({
  author,
  size,
  className,
  disableThreadcast = false
}: SmallAvatarProps) => {
  const location = useLocation();
  const threadcasts = useAppStore(store => store.discussion.threadcasts);

  const threadcast_author = threadcasts?.find?.(t => t?.author === author);
  const threadcast_member = threadcasts?.find?.(t =>
    t?.replies?.find((r: string) => r.split("/")[0] === author)
  );

  const threadcast = threadcast_author || threadcast_member;
  const [profile, setAuthorData] = useState(null);

  const [error, setError] = useState();

  useEffect(() => {
    void (async function () {
      if (profile) return;
      const data = await cache.getAccount(author);
      setAuthorData(data);
    })();
  }, [author]);

  const backupImage = profile?.posting_json_metadata?.profile?.profile_image;

  if (
    threadcast &&
    !disableThreadcast &&
    location.pathname.startsWith("/threads") &&
    typeof size === "number" &&
    size > 8
  ) {
    const exceedThreeHours =
      Math.abs(moment(threadcast.created).diff(moment(), "hours")) > 3;

    return (
      <Link
        to={`/threads/view/${threadcast.author}/${threadcast.permlink}`}
        suppressHydrationWarning={true}
        id={threadcast?.author}
        className={cn(
          `
          relative flex flex-col flex-wrap justify-center shrink-0 rounded-full
          outline outline-2 outline-solid outline-offset-2 outline-purple-500 dark:outline-purple-400
          cursor-pointer hover:opacity-80 transition-opacity`,
          className,
          {
            "outline-gray-200 dark:outline-zinc-700": exceedThreeHours
          }
        )}
      >
        <span
          className="h-full w-full bg-cover flex flex-col flex-wrap justify-center rounded-full border border-pri
        dark:border-pri-d overflow-hidden"
          style={{
            backgroundImage: error && `url(${backupImage})`
          }}
        >
          <img
            loading="lazy"
            onError={element => {
              setError(true);
              element.currentTarget.style.display = "none";
            }}
            className="h-full w-full object-cover rounded-full select-none"
            src={getAvatar(author, size)}
            id={author}
            data-prevent-view
          />
        </span>

        <span
          className={classNames(
            "absolute -bottom-1 -right-1 flex justify-center items-center w-6 h-6 border-2 rounded-full",
            {
              "border-pri dark:border-pri-d bg-purple-500 dark:bg-purple-400 text-pri-d dark:text-pri":
                !exceedThreeHours,
              "border-gray-200 dark:border-zinc-700 bg-pri dark:bg-pri-d text-pri/50 dark:text-pri-d/50":
                exceedThreeHours
            }
          )}
        >
          {!exceedThreeHours && (
            <span className="absolute w-4 h-4 rounded-full bg-purple-500 dark:bg-purple-400 animate-ping z-0" />
          )}

          <FontAwesomeIcon icon={faBolt} size="xs" fixedWidth />
        </span>
      </Link>
    );
  }

  const title = `avatar of @${author}`;
  return (
    <div
      id={author}
      className={cn(
        "flex flex-col flex-wrap justify-center rounded-full border border-pri dark:border-pri-d overflow-hidden",
        className
      )}
    >
      <span
        className={classNames(
          "w-full bg-cover bg-no-repeat bg-center object-cover rounded-full select-none",
          {
            "h-full": error
          }
        )}
        style={{
          backgroundImage: error && `url(${backupImage})`
        }}
      >
        <img
          loading="lazy"
          onError={element => {
            setError(true);
            element.currentTarget.style.display = "none";
          }}
          className="w-full object-cover rounded-full select-none"
          src={getAvatar(author, size)}
        />
      </span>
    </div>
  );
};

interface SizedSmallAvatarProps {
  author: HiveContent["author"];
  disableThreadcast?: boolean;
  className?: string;
}

export function SmallAvatar4({ author, className }: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={4}
      className={classNames("h-4 w-4", className)}
    />
  );
}

export function SmallAvatar6({
  author,
  disableThreadcast,
  className
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={6}
      disableThreadcast={disableThreadcast}
      className={classNames("h-6 w-6", className)}
    />
  );
}

export function SmallAvatar8({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={8}
      disableThreadcast={disableThreadcast}
      className="h-8 w-8"
    />
  );
}

export function SmallAvatar10({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={10}
      disableThreadcast={disableThreadcast}
      className="h-10 w-10"
    />
  );
}

export function SmallAvatar12({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={12}
      disableThreadcast={disableThreadcast}
      className="h-12 w-12"
    />
  );
}

export function SmallAvatar14({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={14}
      disableThreadcast={disableThreadcast}
      className="h-14 w-14"
    />
  );
}

export function SmallAvatar16({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      className="h-16 w-16"
      size={16}
      disableThreadcast={disableThreadcast}
    />
  );
}

export function SmallAvatar18({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      size={18}
      disableThreadcast={disableThreadcast}
      className="h-18 w-18"
    />
  );
}

export function SmallAvatar24({
  author,
  disableThreadcast
}: SizedSmallAvatarProps) {
  return (
    <SmallAvatar
      author={author}
      className="h-24 w-24 select-none"
      disableThreadcast={disableThreadcast}
      size={24}
    />
  );
}

interface SmallAvatarObject {
  [key: number]: Function;
}

export const SmallAvatarObject: SmallAvatarObject = {
  4: SmallAvatar4,
  8: SmallAvatar8,
  10: SmallAvatar10,
  12: SmallAvatar12,
  14: SmallAvatar14,
  16: SmallAvatar16,
  18: SmallAvatar16,
  24: SmallAvatar24
};

interface callSmallAvatar {
  imageSize: number;
  author: HiveContent["author"];
}

export function callSmallAvatar({ imageSize, author }: callSmallAvatar) {
  return SmallAvatarObject[imageSize]({ author });
}
